<template>
  <custom-vue-select
    id="offer-select"
    label="Offer"
    text-field="title"
    placeholder="Please type 3 chars to search"
    :rules="rules"
    :multiple="$attrs.multiple"
    :value-reducer="option=>option"
    :options="offers"
    :selected.sync="offerProxy"
    :data-provider="getOfferOptions"
  />
</template>
<script>
import CustomVueSelect from '@/common/components/common/FormInputs/CustomVueSelect.vue'
import Offers from '@/common/compositions/Offers/OffersApi'

export default {
  name: 'OfferSelect',
  components: {
    CustomVueSelect,
  },
  props: {
    offer: { type: [Array, Object], default: () => {} },
    filter: { type: Object, default: () => {} },
    rules: { type: String, default: '' },
  },
  data() {
    return {
      offers: [],
    }
  },
  computed: {
    offerProxy: {
      get() {
        return this.offer
      },
      set(value) {
        this.$emit('update:offer', value)
      },
    },
  },
  setup() {
    const { listOffersRequest } = Offers()
    return { listOffersRequest }
  },
  created() {
    this.getOfferOptions()
  },
  methods: {
    getOfferOptions(title) {
      this.listOffersRequest({ ...this.filter, title }).then(res => {
        this.offers = res.data.data
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
