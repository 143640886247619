<template>
  <div>
    <ads
      classification="community_offer"
    >
      <template #ad-select-parent="{ad}">
        <div>
          <offer-select
            :offer.sync="ad.typeModel"
            rules="required"
            @update:offer="ad.type='App\\Models\\CommunityOffer'"
          />
        </div>
      </template>
    </ads>
  </div>
</template>
<script>
import Ads from '@/common/components/AppContent/ads/list.vue'
import OfferSelect from '@/common/components/Offers/OfferSelect.vue'

export default {
  name: 'OfferAds',
  components: { Ads, OfferSelect },
}
</script>
<style lang="">
</style>
